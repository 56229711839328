import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Grid, Button } from 'semantic-ui-react'

export default class ConfigToggle extends PureComponent {
  static propTypes={
    value: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    buttonLabels: PropTypes.string,
    onToggle: PropTypes.func,
    disabled: PropTypes.bool,
    notes: PropTypes.element,
    loading: PropTypes.bool
  }

  render() {
    const {buttonLabels, label, value, disabled, onToggle, name, loading, notes} = this.props

    const lbls = (buttonLabels || 'Yes/No').split('/')
    const labelYes = lbls[0]
    const labelNo = lbls[1]

    return (
      <Grid centered padded textAlign="center" columns="equal">
        <Grid.Column textAlign="center" width={8}>
          <p>{label}</p>
        </Grid.Column>

        <Grid.Column>
          <Button.Group>
            <Button
              data-testid={`${name}_toggle_yes`}
              className={value ? "active" : null}
              content={labelYes}
              color={value ? "black" : null}
              disabled={disabled}
              onClick={(e) => (value ? null : onToggle(e, name))}
              loading={loading}
            />
            <Button.Or />
            <Button
              data-testid={`${name}_toggle_no`}
              className={value ? null : "active"}
              content={labelNo}
              color={value ? null : "black"}
              disabled={disabled}
              onClick={(e) => (value ? onToggle(e, name) : null)}
              loading={loading}
            />
          </Button.Group>
          {notes}
        </Grid.Column>
      </Grid>
    );
  }
}
